import React, {useCallback, useState} from "react";
import PropTypes from 'prop-types';
import useCmsArticlesFeeds from "./../useCmsArticlesFeeds";
import ArticlesList from "./ArticlesList";
import handleClick from "@fik/utils/handleClick";


const ArticlesFeed = ({group, limit = 5}) => {
    const {articles, categories, selectCategory, selected} = useCmsArticlesFeeds(group);
    const [page, setPage] = useState(1);

    const handelClickOnCategory = useCallback((index) => {
        selectCategory(index);
        setPage(1);
    }, [selectCategory]);

    const offset = limit * (page - 1);

    const handlePrevPage = useCallback(() => {
        if (page > 1) {
            setPage(page - 1);
        }
    }, [page]);

    const handleNextPage = useCallback(() => {
        if (page <= articles.length/limit - 1) {
            setPage(page + 1);
        }
    }, [articles, limit, page]);

    if (!categories) {
        return null;
    }

    return <div className="panel blank-panel">
        <div className="ibox-content">
            <div className="panel-heading">
                <div className="panel-options">
                    <ul className="nav nav-tabs" data-bind="foreach: feeds">
                        {categories.map((item, index) => <li key={item.code} className={item.code === selected.code ? 'active' : null}>
                            <a onClick={handleClick(() => handelClickOnCategory(index))}>{item.label}</a>
                        </li>)}
                    </ul>
                </div>
            </div>
            <div className="panel-body">
                <div className="tab-content">
                    {articles ? <ArticlesList articles={articles.slice(offset, offset + limit)}/> : null}
                    {articles ? <div className="btn-group">
                        <button className="btn btn-info btn-xs"
                                disabled={page <= 1}
                                onClick={handleClick(handlePrevPage)}
                        >Novější</button>
                        <button className="btn btn-info btn-xs"
                                disabled={page > articles.length/limit - 1}
                                onClick={handleClick(handleNextPage)}
                        >Starší</button>
                    </div> : null}
                </div>
            </div>
        </div>
    </div>;
};

ArticlesFeed.propTypes = {
    group: PropTypes.number,
    limit: PropTypes.number
};

export default ArticlesFeed;