import React from 'react';
import {useEntity} from "@fik/entity";
import Salesman from "../../components/Profile/Salesman/Salesman";
import {Col, Row} from "@fik/ui";

const EsetContacts = () => {
    const {
        entity: resellerProfile
    } = useEntity({name: 'profile'}, null);

    return (
        <div className="wrapper wrapper-content animated fadeInRight" data-app-module="modules/app">
            <Row>
                <Col lg={6}>
                    <div className="contact-box contact-box-main no-borders">
                        <div>
                            <h3>
                                <strong>Váš obchodník</strong>
                            </h3>
                            <Row>
                                <Col sm={7} className="no-padding no-margins no-padding">
                                    {resellerProfile && resellerProfile.staff ?
                                        <Salesman moreLink={false} salesman={resellerProfile.staff} path={process.env.REACT_APP_API_URL}/> : null}
                                </Col>
                                <Col sm={5}>
                                    <iframe style={{border: 'none'}} src="https://frame.mapy.cz/s/fovakajade" width="100%" height="200" frameBorder="0"></iframe>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>

                <Col lg={6}>
                    <div className="contact-box contact-box-main no-borders">
                        <h3>
                            <strong>Obecné informace</strong>
                        </h3>
                        <Row>
                            <Col lg={6}>
                                <address>
                                    <strong>ESET software spol. s r.o.</strong><br/>
                                    Classic 7 Business Park<br/>
                                    Jankovcova 1037/49<br/>
                                    170 00 Praha 7
                                </address>
                            </Col>
                            <Col lg={6}>
                                <address>
                                    <strong>IČ: </strong>26467593, <strong>DIČ: </strong>CZ26467593<br/>
                                    <br/>
                                    <strong>Tel:</strong> +420 233 090 233<br/>
                                    <strong>E-mail:</strong> <a href="mailto:info@eset.cz">info@eset.cz</a>
                                </address>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col lg={3}>
                    <div className="contact-box contact-box-department">
                        <h3>
                            <strong>Obchodní oddělení</strong>
                        </h3>
                        <address>
                            <strong>Telefon:</strong> +420 222 811 135<br/>
                            <strong>E-mail:</strong> <a href="mailto:partner@eset.cz">partner@eset.cz</a>
                        </address>
                        V pracovní dny od 8:30 do 17:00
                    </div>
                </Col>
                <Col lg={3}>
                    <div className="contact-box contact-box-department">
                        <h3>
                            <strong>Technické oddělení</strong>
                        </h3>
                        <address>
                            <strong>Telefon:</strong> +420 233 090 233<br/>
                            <strong>Kontaktní formulář:</strong> <a
                            href="https://www.eset.com/cz/podpora/kontakt/"
                            rel="noopener noreferrer"
                            target="_blank">ZDE</a>
                        </address>
                        V pracovní dny od 8:30 do 17:00
                    </div>
                </Col>
                <Col lg={3}>
                    <div className="contact-box contact-box-department">
                        <h3>
                            <strong>Marketingové oddělení</strong>
                        </h3>
                        <address>
                            <strong>Telefon:</strong> +420 233 090 253<br/>
                            <strong>E-mail:</strong> <a href="mailto:marketing@eset.cz">marketing@eset.cz</a>
                        </address>
                        V pracovní dny od 8:30 do 17:00
                    </div>
                </Col>
                <Col lg={3}>
                    <div className="contact-box contact-box-department">
                        <h3>
                            <strong>Fakturace a finanční oddělení</strong>
                        </h3>
                        <address>
                            <strong>Telefon:</strong> + 420 233 090 269<br/>
                            <strong>E-mail:</strong> <a href="mailto:fakturace@eset.cz">fakturace@eset.cz</a>
                        </address>
                        V pracovní dny od 8:30 do 17:00
                    </div>
                </Col>
            </Row>

            <Row>
                <Col lg={3}>
                    <div className="contact-box contact-box-department">
                        <h3>
                            <strong>Dotazy na GDPR</strong>
                        </h3>
                        <address>
                            <strong>E-mail:</strong> <a href="mailto:dpo@eset.cz">dpo@eset.cz</a>
                        </address>
                        V pracovní dny od 8:30 do 17:00
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default EsetContacts;