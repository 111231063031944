import React from 'react';
import {ArticlesFeed} from "@fik/cms";
import ResellerDashboard from "../../components/ResellerDashboard/ResellerDashboard";
import {Col, Row} from "@fik/ui";
import HomapagePopup from "./HomapagePopup";

const Homepage = () => {
    return (<>
        <HomapagePopup />
        <Row>
            <Col lg={12}>
                <ResellerDashboard/>
            </Col>
        </Row>
        <Row>
            <Col lg={12}>
                <ArticlesFeed group={1}/>
            </Col>
        </Row>
    </>);
};

export default Homepage;